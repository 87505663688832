import { Button, FormInstance } from 'antd';
import React from 'react';
import AltaPrescriptorFormContainer, { IState as IStateContainer } from './AltaPrescriptorFormContainer';
import logoEreceta from '../images/logoEReceta.png';
import logoErecetaW from '../images/logoERecetaW.png';
import logoVidal from '../images/VIDAL_Vademecum_españa_FINAL.png';
import logoDelonia from '../images/logo_delonia_nd7.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faEgg, faLock, faPills, faUser } from '@fortawesome/free-solid-svg-icons';

import AltaPrestadorFromExternalData from '../login/entities/altaPrescriptorFromExternalData';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
    closeForm: (form?: FormInstance) => void;
    prestadorRegistroData?: AltaPrestadorFromExternalData;
    fromExternalAccess?: boolean;
    showForm?: boolean;
}

const AltaPrescriptorPage = (props: IProps & IStateContainer) => {

    const { t } = useTranslation('welcome');
    const isSmallDevice = useMediaQuery({ query: '(max-width: 500px)' });
    const history = useHistory();

    return (
        <>
            <div className='enlace_token_container prescriptorRegistration'>
                <div className='enlace_token_card_container'>
                    <div className='header'>
                        <a href="https://www.delonia.com/ereceta/" target='_blank'>
                            <img src={logoErecetaW} alt='' />
                        </a>
                        <Button type='link' size='large' icon={isSmallDevice ? <FontAwesomeIcon icon={faUser} /> : undefined}
                            onClick={() => history.push('/login')}>
                            {isSmallDevice ? "" : 'Acceso de usuarios registrados'}</Button>
                    </div>
                    <div className='bloque-altaPrescriptor'>
                        <div className="altaPrescriptor">
                            <div className='informacionReceta-altaPrescriptor'>
                                <div className='logos'>
                                    <img src={logoEreceta} className="logoReceta" alt='' />
                                    <img src={logoDelonia} className="logoDelonia" alt='' />
                                    <img src={logoVidal} className="logoVidal" alt='' />
                                </div>
                                <h2>
                                    <strong>
                                        Receta Digital Privada Homologada
                                        recomendada por ICOMV
                                    </strong>
                                </h2>
                                <div className='item-informacionReceta'>
                                    <p>
                                        Date de alta en el sistema de receta y pruébalo gratis durante un mes.
                                    </p>
                                    <FontAwesomeIcon size='3x' color='#0cb7f2' icon={faExternalLinkAlt} />
                                </div>
                                <div className='item-informacionReceta'>
                                    <p>
                                        ¿Aún no te decides?<br />
                                        Te informamos de cómo funciona y de todas las ventajas que te proporciona
                                    </p>
                                    <FontAwesomeIcon size='3x' color='#d2054f' icon={faExternalLinkAlt} flip='both' />
                                </div>



                            </div>
                            <div className='formulario-altaPrescriptor'>
                                <AltaPrescriptorFormContainer
                                    closeForm={props.closeForm}
                                    fromExternalAccess={true}
                                    showForm={true}
                                    existingPrestadorData={props.prestadorRegistroData}
                                />
                            </div>
                        </div>

                        <div className='bloqueVentajas-altaPrescriptor'>
                            <div className='bloqueVentajas--inner'>
                                <h2>Ventajas para ti y tus pacientes</h2>
                                <div className='ventajas-bloqueVentajas'>
                                    <div className='item-ventajas'>
                                        <FontAwesomeIcon className="iconVentaja" size='4x' icon={faEgg} />
                                        <div className='def-item-ventajas'>
                                            <p>Facilidad de uso</p>
                                            Es muy fácil de prescribir y aprovechar todas las funcionalidades
                                        </div>

                                    </div>
                                    <div className='item-ventajas'>
                                        <FontAwesomeIcon className="iconVentaja" size='4x' icon={faLock} />
                                        <div className='def-item-ventajas'>
                                            <p>Seguridad</p>
                                            El sistema cuenta con todas las garantías de seguridad para ti y tus pacientes
                                        </div>
                                    </div>
                                    <div className='item-ventajas'>
                                        <FontAwesomeIcon className="iconVentaja" size='4x' icon={faPills} />
                                        <div className='def-item-ventajas'>
                                            <p>Dispensación digital</p>
                                            Todo es mas fácil para tu paciente, quien podrá retirar el tratamiento sin presentar ningún papel
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AltaPrescriptorPage;